<template>
  <div :class="pattern">
    <div class="form-check  text-left">
      <!-- <b-form-checkbox-group
        @change="clickAnswer"
        v-model="selected"
        :id="model.key"
      >
        <b-form-checkbox :value="true">{{ model.name }}</b-form-checkbox>
      </b-form-checkbox-group> -->
      <b-form-checkbox
        :disabled="user ? user.role === 1 : false"
        :id="model.key"
        @change="clickAnswer"
        v-model="selected"
        :name="model.key"
        :value="true"
        :unchecked-value="false"
      >
        {{ model.name }}
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  props: ["model", "isSingle", "setData", "pattern"],
  created() {
    this.selected = !!parseInt(this.setData);
  },
  watch: {
    setData: function (newVal) {
      if (this.selected !== newVal) {
        this.selected = !!parseInt(newVal);
      }
    },
  },
  methods: {
    clickAnswer() {
      if (this.user.role !== 1) {
        this.$emit("clickAnswer", {
          name: this.model.key,
          value: this.selected,
        });
      }
    },
  },
};
</script>

<style>
</style>