<template>
  <b-form-group v-slot="{ ariaDescribedby }">
    <b-form-radio-group
      v-model="selected"
      :id="topic"
      :aria-describedby="ariaDescribedby"
    >
      <div :class="`row ${pattern}-radio`">
        <div
          class="mt-3 text-left"
          v-for="(item, i) in choice"
          :key="i"
          :class="item.grid"
        >
          <div
            @click="clickAnswer(item)"
            :class="
              (item.value !== undefined ? item.value : item.name) === selected
                ? `btn btn-${pattern}-outline-selected`
                : `btn btn-${pattern}-outline`
            "
            style="width: 100%"
          >
            <b-form-radio
              :disabled="user ? user.role === 1 : false"
              :value="item.value !== undefined ? item.value : item.name"
              >{{ item.name }}</b-form-radio
            >
          </div>
        </div>
      </div>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["choice", "topic", "setData","pattern"],
  data() {
    return {
      selected: null,
    };
  },
  created() {
    let value = this.setData;
    if (value === 1) value = true;
    else if (value === 0) value = false;
    this.selected = value;
  },
  computed: {
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  watch: {
    setData: function (newVal) {
      let value = newVal;
      if (value === 1) value = true;
      else if (value === 0) value = false;
      this.selected = value;
    },
  },
  methods: {
    clickAnswer(item) {
      if (this.user.role !== 1) {
        const answer = item.value !== undefined ? item.value : item.name;
        this.$emit("clickAnswer", answer);
        this.selected = answer;
      }
    },
  },
};
</script>

<style>
</style>
