<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-green-1-theme
            d-flex
            justify-content-between
          "
        >
          <div>3. ข้อมูลเกี่ยวกับการตั้งครรภ์ไม่พึงประสงค์</div>
          <div>หมายเลขเอกสาร : {{ mainForm.oscc_code }}</div>
        </div>
        <div class="card-body">
          <div class="form-group row">
            <label class="col-4 col-form-label required"
              >ตั้งครรภ์ครั้งแรกเมื่ออายุ (ปี)</label
            >
            <div class="col-3">
              <b-form-group id="fist_pregnant_age">
                <b-form-input
                  :readonly="isAdmin"
                  id="fist_pregnant_age"
                  name="fist_pregnant_age"
                  min="0"
                  v-model="$v.formData.fist_pregnant_age.$model"
                  :state="validateState('fist_pregnant_age')"
                  type="number"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณากรอกตั้งครรภ์ครั้งแรกเมื่ออายุ
                  (ปี)</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label required"
              >อายุครรภ์ปัจจุบัน (สัปดาห์)</label
            >
            <div class="col-3">
              <b-form-group id="gestational_age_week">
                <b-form-input
                  :readonly="isAdmin"
                  id="gestational_age_week"
                  name="gestational_age_week"
                  v-model="$v.formData.gestational_age_week.$model"
                  :state="validateState('gestational_age_week')"
                  type="number"
                  min="0"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณากรอกอายุครรภ์ปัจจุบัน (สัปดาห์)</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label required"
              >การตั้งครรภ์ครั้งนี้เป็นครั้งที่ (ครั้ง)</label
            >
            <div class="col-3">
              <b-form-group id="pregnant_amount">
                <b-form-input
                  :readonly="isAdmin"
                  id="pregnant_amount"
                  name="pregnant_amount"
                  v-model="$v.formData.pregnant_amount.$model"
                  :state="validateState('pregnant_amount')"
                  type="number"
                  min="0"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณากรอกการตั้งครรภ์ครั้งนี้เป็นครั้งที่
                  (ครั้ง)</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label required"
              >จำนวนบุตรคลอดมีชีวิต (คน)</label
            >
            <div class="col-3">
              <b-form-group id="children_amount">
                <b-form-input
                  :readonly="isAdmin"
                  id="children_amount"
                  name="children_amount"
                  v-model="$v.formData.children_amount.$model"
                  :state="validateState('children_amount')"
                  type="number"
                  min="0"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณากรอกจำนวนบุตรคลอดมีชีวิต (คน)</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label required"
              >จำนวนครั้งที่แท้งบุตร (ครั้ง)</label
            >
            <div class="col-3">
              <b-form-group id="abort_amount">
                <b-form-input
                  :readonly="isAdmin"
                  id="abort_amount"
                  name="abort_amount"
                  v-model="$v.formData.abort_amount.$model"
                  :state="validateState('abort_amount')"
                  type="number"
                  min="0"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณากรอกจำนวนครั้งที่แท้งบุตร
                  (ครั้ง)</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label"
              >ประจำเดือนครั้งสุดท้าย (วัน/เดือน/ปี)</label
            >
            <div class="col-3">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    rounded
                    class="mt-2"
                    dense
                    v-model="computedDateFormatted"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.last_period_date"
                  @input="menu = false"
                  locale="th-th"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-4 col-form-label">
              <label class="required">วันที่เกิดเหตุการณ์</label>
            </div>
            <div class="col-3">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      rounded
                      class="mt-2"
                      dense
                      v-model="computedEventDateFormatted"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="formData.event_date"
                    @input="menu1 = false"
                    locale="th-th"
                ></v-date-picker>
              </v-menu>
              <small v-if="!formData.event_date" class="text-danger"
              >กรุณาระบุวันที่เกิดเหตุการณ์</small
              >
            </div>
<!--            <div class="col-2">-->
<!--              <label class="required">เวลา</label>-->
<!--              <input-->
<!--                  v-model="formData.event_time"-->
<!--                  @click="modal2 = !isAdmin"-->
<!--                  class="form-control mt-2"-->
<!--                  type="text"-->
<!--                  readonly-->
<!--              />-->
<!--              <small v-if="!formData.event_time" class="text-danger"-->
<!--              >กรุณาระบุเวลาที่เกิดเหตุการณ์</small-->
<!--              >-->
<!--            </div>-->
          </div>
          <div class="form-group row">
            <div class="col-4">
              <label class="required">วันที่มาโรงพยาบาล</label>
            </div>
            <div class="col-3">
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      rounded
                      class="mt-2"
                      dense
                      v-model="computedHospitalDateFormatted"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="formData.hospital_date"
                    @input="menu2 = false"
                    locale="th-th"
                ></v-date-picker>
              </v-menu>
              <small v-if="!formData.hospital_date" class="text-danger"
              >กรุณาระบุวัน/เดือน/ปีที่ มาโรงพยาบาล</small
              >
            </div>
<!--            <div class="col-2">-->
<!--              <label class="required">เวลา</label>-->
<!--              <input-->
<!--                  v-model="formData.hospital_time"-->
<!--                  @click="modal3 = !isAdmin"-->
<!--                  class="form-control mt-2"-->
<!--                  type="text"-->
<!--                  readonly-->
<!--              />-->
<!--              <small v-if="!formData.hospital_time" class="text-danger"-->
<!--              >กรุณาระบุเวลาที่มาโรงพยาบาล</small-->
<!--              >-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-green-1-theme
            d-flex
            justify-content-between
          "
        >
          <div>4. สาเหตุการตั้งครรภ์ไม่พึงประสงค์</div>
        </div>
        <div class="bg-green-3-theme mt-3 py-2 px-3 required">
          4.1 สาเหตุของการตั้งครรภ์ไม่พึงประสงค์ (เลือกได้มากกว่า 1 ตัวเลือก)
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="pregnant-checkbox"
                  :setData="formData[pregnantCause[0].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="pregnantCause[0]"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.problems_contraceptive" class="offset-2 col-6">
              <RadioGroup
                pattern="pregnant"
                :setData="formData.is_birth_control"
                @clickAnswer="formData.is_birth_control = $event"
                :choice="isBirthControl"
              ></RadioGroup>
            </div>
            <div v-if="formData.problems_contraceptive && formData.is_birth_control" class="offset-2 col-6">
              <div
                v-for="(item, index) in problemsContraceptiveList"
                :key="index"
                class="checkbox-border px-2 py-2 my-3"
              >
                <Checkbox
                  pattern="pregnant-checkbox"
                  :setData="formData[item.key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="item"
                ></Checkbox>
              </div>
             </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="pregnant-checkbox"
                  :setData="formData[pregnantCause[1].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="pregnantCause[1]"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.problems_break_law" class="offset-2 col-6">
              <div
                v-for="(item, index) in problemsBreakLawList"
                :key="index"
                class="checkbox-border px-2 py-2 my-3"
              >
                <Checkbox
                  pattern="pregnant-checkbox"
                  :setData="formData[item.key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="item"
                ></Checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="pregnant-checkbox"
                  :setData="formData[pregnantCause[2].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="pregnantCause[2]"
                ></Checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="pregnant-checkbox"
                  :setData="formData[pregnantCause[3].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="pregnantCause[3]"
                ></Checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="pregnant-checkbox"
                  :setData="formData[pregnantCause[4].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="pregnantCause[4]"
                ></Checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-green-3-theme mt-3 py-2 px-3 required">
          4.2 ปัจจัยประกอบการยุติการตั้งครรภ์ (ตอบได้มากกว่า 1 ข้อ)
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="mb-5" v-for="(item, index) in abortion" :key="index">
                <div class="checkbox-border px-2 py-2 my-3">
                  <Checkbox
                    pattern="pregnant-checkbox"
                    :setData="formData[item.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="true"
                    :model="item"
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row mt-4">
      <div class="col-3">
        <button @click="goto('result')" class="btn btn-cancel-outline">
          ยกเลิก
        </button>
      </div>
      <div class="col-3 offset-3">
        <button
          :disabled="isAdmin"
          @click="save(false)"
          class="btn btn-save-outline"
        >
          บันทึก
        </button>
      </div>
      <div class="col-3">
        <button
          :disabled="isAdmin"
          @click="save(true)"
          class="btn btn-next-outline"
        >
          ถัดไป
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "../Checkbox.vue";
import RadioGroup from "../RadioGroup.vue";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  mixins: [validationMixin],
  components: {
    Checkbox,
    RadioGroup
  },
  computed: {
    computedEventDateFormatted() {
      if (!this.formData.event_date) return null;
      const [year, month, day] = this.formData.event_date.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    computedHospitalDateFormatted() {
      if (!this.formData.hospital_date) return null;
      const [year, month, day] = this.formData.hospital_date.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    computedDateFormatted() {
      if (!this.formData.last_period_date) return null;
      const [year, month, day] = this.formData.last_period_date.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  validations: {
    formData: {
      fist_pregnant_age: {
        required,
      },
      gestational_age_week: {
        required,
      },
      pregnant_amount: {
        required,
      },
      children_amount: {
        required,
      },
      abort_amount: {
        required,
      },
    },
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      modal2: false,
      modal3: false,
      isAdmin: false,
      menu: false,
      abortion: [
        { name: "ปัญหาสุขภาพกาย/จิตแม่", key: "health_mental_mother" },
        { name: "สุขภาพเด็ก", key: "health_mental_child" },
        { name: "ความผิดทางเพศ", key: "anomaly_sex" },
        {
          name: "อายุครรภ์ต่ำกว่า 12 สัปดาห์",
          key: "gestational_age_under_twelve",
        },
        {
          name: "อายุครรภ์ 12 – 20 สัปดาห์ขึ้นไป ให้คำปรึกษา",
          key: "gestational_age_over_twelve",
        },
      ],
      pregnantCause: [
        { name: "ปัญหาจากการคุมกำเนิด", key: "problems_contraceptive" },
        {
          name: "ปัญหาการตั้งครรภ์ที่เกิดจากการกระทำความผิดทางกฎหมาย",
          key: "problems_break_law",
        },
        { name: "ปัญหาทางด้านเศรษฐกิจ/สังคม", key: "social_economic_problem" },
        { name: "ปัญหาสัมพันธภาพภายในครอบครัว", key: "family_problem" },
        {
          name: "ปัญหาทางด้านการแพทย์ ตามมาตรา305(1)(2) ",
          key: "medical_problem_305",
        },
      ],
      problemsBreakLawList: [
        {
          name: "กระทำชำเราเด็กอายุต่ำกว่า 15 ปี",
          key: "offend_under_fifteen",
        },
        {
          name: "กระทำชำเราบุคคลอายุ 15 ปีขึ้นไปแต่ไม่ถึง 18 ปีบริบูรณ์",
          key: "offend_over_fifteen_under_eighteen",
        },
        {
          name: "กระทำชำเราบุคคลอายุ 18 ปีขึ้นไป.",
          key: "offend_over_eighteen",
        },
      ],
      isBirthControl: [
        { name: "คุมกำเนิด",  value: true, grid: "col-6" },
        { name: "ไม่ได้คุมกำเนิด",  value: false, grid: "col-6" },
      ],
      problemsContraceptiveList: [
        { name: "ยาเม็ดคุมกำเนิดฉุกเฉิน", key: "birth_control_pill_emer" },
        { name: "ยาเม็ดคุมกำเนิด", key: "birth_control_pill" },
        { name: "ยาฉีดคุมกำเนิด", key: "birth_control_inject" },
        { name: "ยาฝังคุมกำเนิด", key: "birth_control_implant" },
        { name: "ถุงยางอนามัย", key: "birth_control_condom" },
        { name: "ใส่ห่วงอนามัย", key: "birth_control_iud" },
        { name: "ทำหมัน", key: "birth_control_sterilization" },
        { name: "หลั่งข้างนอก", key: "birth_control_out_ejaculation" },
      ],
      mainForm: {
        oscc_code: 0,
      },
      formData: {
        form_id: "",
        user_id: "",
        fist_pregnant_age: "",
        gestational_age_week: "",
        pregnant_amount: "",
        children_amount: "",
        abort_amount: "",
        last_period_date: "",
        problems_contraceptive: "",
        is_birth_control: false,
        no_birth_control: "",
        birth_control: "",
        birth_control_pill: "",
        birth_control_pill_emer: "",
        birth_control_condom: "",
        birth_control_iud: "",
        birth_control_sterilization: "",
        birth_control_out_ejaculation: "",
        problems_break_law: "",
        offend_under_fifteen: "",
        offend_over_fifteen_under_eighteen: "",
        offend_over_eighteen: "",
        social_economic_problem: "",
        medical_problem_305: "",
        health_mental_mother: "",
        health_mental_child: "",
        anomaly_sex: "",
        gestational_age_under_twelve: "",
        gestational_age_over_twelve: "",
        hospital_date: "",
        hospital_time: "",
        event_date: "",
        event_time: "",
      },
    };
  },
  created() {
    Swal.fire({
      title: "กำลังเรียกข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        if (this.$route.query.form) {
          this.formData.form_id = this.$route.query.form;
          await this.$store
            .dispatch("Pregnant/showForm2", this.$route.query.form)
            .then((response) => {
              if (response.main_form) {
                this.mainForm = response.main_form;
              }
              if (response.form) {
                this.formData = response.form;
                if (this.formData.last_period_date) {
                  this.formData.last_period_date = moment(
                    response.form.last_period_date,
                    "YYYY-MM-DD"
                  )
                    .subtract(543, "years")
                    .format("YYYY-MM-DD");
                }

                let refer = response.refer;
                this.isAdmin = this.user ? this.user.role == 1 : false;
                let isRefer = false;
                if (refer) {
                  if (this.user.agency_code == refer.from && refer.status == 0)
                    isRefer = false;
                  else if (
                    this.user.agency_code == refer.from &&
                    refer.status == 1
                  )
                    isRefer = true;
                  else if (
                    this.user.agency_code == refer.to &&
                    refer.status == 0
                  )
                    isRefer = true;
                  else if (
                    this.user.agency_code == refer.to &&
                    refer.status == 1
                  )
                    isRefer = false;
                }
                if (!this.isAdmin) this.isAdmin = isRefer;
              } else {
                this.convertDate();
              }
            });
        } else {
          this.convertDate();
        }
        Swal.clickConfirm();
      },
    });
  },
  methods: {
    convertDate() {},
    setData(model) {
      this.formData[model.name] = model.value;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? !$error : null;
    },
    goto(path) {
      this.$router.push({
        path: path,
      });
    },
    save(nextPage) {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        Swal.fire({
          icon: "error",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          confirmButtonText: "ตกลง",
        });
        return;
      }
      this.formData.user_id = this.user ? this.user.id : "";
      if (this.formData.last_period_date) {
        this.formData.last_period_date = moment(
          this.formData.last_period_date,
          "YYYY-MM-DD"
        )
          .add(543, "years")
          .format("YYYY-MM-DD");
      }
      Swal.fire({
        title: "กำลังบันทึกข้อมูล",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          this.$store
            .dispatch("Pregnant/saveForm2", this.formData)
            .then((response) => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#4579DF",
                }).then(() => {
                  if (nextPage) {
                    this.$router.push({
                      path: "pregnant",
                      query: { page: 3, form: this.$route.query.form },
                    });
                  } else {
                    this.$router.push({
                      path: "pregnant",
                      query: { page: 2, form: this.$route.query.form },
                    });
                    window.location.reload();
                  }
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "บันทึกไม่สำเร็จ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#4579DF",
                });
              }
            });
        },
      });
    },
  },
};
</script>

<style>
</style>
