<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-green-1-theme
            d-flex
            justify-content-between
          "
        >
          <div>5. การให้คำปรึกษาทางเลือก</div>
          <div>หมายเลขเอกสาร : {{ mainForm.oscc_code }}</div>
        </div>
        <div class="bg-green-3-theme mt-3 py-2 px-3 required">
          5.1 การให้บริการ
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <RadioGroup
                pattern="pregnant"
                :setData="formData.service"
                @clickAnswer="formData.service = $event"
                :choice="service"
              ></RadioGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-green-1-theme
            d-flex
            justify-content-between
          "
        >
          <div>6. ผลการตัดสินใจหลังให้การปรึกษาทางเลือก</div>
        </div>
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-12">
              <label>6.1 ตั้งครรภ์ต่อ</label>
            </div>
            <div class="col-6">
              <RadioGroup
                pattern="pregnant"
                :setData="formData.abortion"
                @clickAnswer="formData.abortion = $event"
                :choice="[abortion[0]]"
              ></RadioGroup>
            </div>
            <div v-if="formData.abortion === 'ฝากครรภ์'" class="col-2">
              ระบุสถานที่
            </div>
            <div v-if="formData.abortion === 'ฝากครรภ์'" class="col-4">
              <input
                :readonly="isAdmin"
                v-model="formData.antenatal_care_place"
                class="form-control mt-2"
                type="text"
              />
            </div>
            <div class="col-12">
              <hr class="pregnant-line" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label>6.2 ยุติการตั้งครรภ์</label>
              <RadioGroup
                pattern="pregnant"
                :setData="formData.abortion"
                @clickAnswer="formData.abortion = $event"
                :choice="[abortion[1]]"
              ></RadioGroup>
            </div>
            <div
              v-if="formData.abortion === 'ยุติการตั้งครรภ์ในโรงพยาบาล'"
              class="offset-2 col-6"
            >
              <div v-for="(item, index) in abortionList" :key="index">
                <div class="checkbox-border px-2 py-2 my-3">
                  <Checkbox
                    pattern="pregnant-checkbox"
                    @clickAnswer="setData($event)"
                    :setData="formData[item.key]"
                    :isSingle="true"
                    :model="item"
                  ></Checkbox>
                </div>
              </div>
              <!--               <RadioGroup
                pattern="pregnant"
                :setData="formData.abortion_process"
                @clickAnswer="formData.abortion_process = $event"
                :choice="abortionList"
              ></RadioGroup> -->
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-6">
              <RadioGroup
                pattern="pregnant"
                :setData="formData.abortion"
                @clickAnswer="formData.abortion = $event"
                :choice="[abortion[2]]"
              ></RadioGroup>
            </div>
            <div
              v-if="
                formData.abortion === 'ส่งต่อ/แนะนำเครือข่ายยุติการตั้งครรภ์'
              "
              class="col-2"
            >
              ระบุสถานที่
            </div>
            <div
              v-if="
                formData.abortion === 'ส่งต่อ/แนะนำเครือข่ายยุติการตั้งครรภ์'
              "
              class="col-4"
            >
              <input
                :readonly="isAdmin"
                v-model="formData.abortion_place"
                class="form-control mt-2"
                type="text"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <RadioGroup
                pattern="pregnant"
                :setData="formData.abortion"
                @clickAnswer="formData.abortion = $event"
                :choice="[abortion[3]]"
              ></RadioGroup>
            </div>
            <div
              v-if="formData.abortion === 'คุมกำเนิด'"
              class="offset-2 col-6"
            >
              <RadioGroup
                pattern="pregnant"
                :setData="formData.contraceptive_process"
                @clickAnswer="formData.contraceptive_process = $event"
                :choice="contraceptiveList"
              ></RadioGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-green-1-theme
            d-flex
            justify-content-between
          "
        >
          <div>7. สถานะการให้บริการ</div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <RadioGroup
                pattern="pregnant"
                :setData="formData.service_status"
                @clickAnswer="formData.service_status = $event"
                :choice="isService"
              ></RadioGroup>
            </div>
          </div>
          <div
            v-if="formData.service_status === 'สิ้นสุดการให้บริการ'"
            class="row align-items-end"
          >
            <div class="offset-2 col-4">
              <RadioGroup
                pattern="pregnant"
                :setData="formData.service_status_denied"
                @clickAnswer="formData.service_status_denied = $event"
                :choice="endService"
              ></RadioGroup>
            </div>
            <div
              v-if="
                formData.service_status_denied ===
                'ส่งต่อไปรักษายังหน่วยงานอื่น ๆ'
              "
              class="col-2"
            >
              <small>รหัสหน่วยงาน (5 หลัก)</small>
              <v-autocomplete
                v-model="model"
                dense
                @keyup="searchHosp(search, 'hospcode')"
                @change="onSelect(model)"
                :readonly="isAdmin"
                :items="hospitalList"
                item-text="hospcode"
                item-value="hospcode"
                :loading="isLoading"
                :search-input.sync="search"
                clearable
                rounded
                solo
                return-object
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.hospcode }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.hospcode }} - {{ data.item.name }}
                </template>
              </v-autocomplete>
            </div>
            <div
              v-if="
                formData.service_status_denied ===
                'ส่งต่อไปรักษายังหน่วยงานอื่น ๆ'
              "
              class="col-4"
            >
              <small>ชื่อหน่วยงาน</small>
              <v-autocomplete
                v-model="hspModel"
                dense
                @keyup="searchHosp(hspSearch, 'name')"
                @change="onSelect(hspModel)"
                :readonly="isAdmin"
                :items="hospitalList"
                item-text="name"
                item-value="name"
                :loading="isLoading"
                :search-input.sync="hspSearch"
                rounded
                clearable
                solo
                return-object
              >
              </v-autocomplete>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row mt-4">
      <div class="col-3">
        <button @click="goto('result')" class="btn btn-cancel-outline">
          ยกเลิก
        </button>
      </div>
      <div class="col-3 offset-3">
        <button
          :disabled="isAdmin"
          @click="gotoViolence"
          class="btn btn-save-outline"
        >
          เพิ่มเคสผู้ที่ถูกกระทำความรุนแรง
        </button>
      </div>
      <div class="col-3">
        <button
          :disabled="isAdmin"
          @click="save()"
          class="btn btn-save-outline"
        >
          บันทึก
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RadioGroup from "../RadioGroup.vue";
import Checkbox from "../Checkbox.vue";
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  components: {
    RadioGroup,
    Checkbox,
  },
  computed: {
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  created() {
    Swal.fire({
      title: "กำลังเรียกข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        if (this.$route.query.form) {
          this.formData.form_id = this.$route.query.form;
          await this.$store
            .dispatch("Pregnant/showForm3", this.$route.query.form)
            .then((response) => {
              if (response.main_form) {
                this.mainForm = response.main_form;
              }
              if (response.form) {
                if (response.form.hospcode) {
                  this.model = response.form.hospcode;
                  this.hspModel = response.form.hospcode;
                  this.hospitalList.push(response.form.hospcode);
                }
                delete response.form.hospcode;
                this.formData = response.form;
                this.mainForm = response.main_form;
              }
              let refer = response.refer;
              this.isAdmin = this.user ? this.user.role == 1 : false;
              let isRefer = false;
              if (refer) {
                if (this.user.agency_code == refer.from && refer.status == 0)
                  isRefer = false;
                else if (
                  this.user.agency_code == refer.from &&
                  refer.status == 1
                )
                  isRefer = true;
                else if (this.user.agency_code == refer.to && refer.status == 0)
                  isRefer = true;
                else if (this.user.agency_code == refer.to && refer.status == 1)
                  isRefer = false;
              }
              if (!this.isAdmin) this.isAdmin = isRefer;
            });
        }
        Swal.clickConfirm();
      },
    });
  },
  methods: {
    onSelect(keyword) {
      if (keyword) {
        this.model = keyword;
        this.hspModel = keyword;
        this.formData.hosp_code = keyword.hospcode;
        this.formData.hosp_name = keyword.name;
      } else {
        this.model = null;
        this.hspModel = null;
        this.formData.hosp_code = "";
        this.formData.hosp_name = "";
      }
    },
    async searchHosp(val, key) {
      if (this.isLoading) return;
      this.isLoading = true;
      let model = {};
      model[key] = val;
      await this.$store
        .dispatch("MasterData/searchHospCode", model)
        .then((response) => {
          this.hospitalList = response.data;
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    gotoViolence() {
      this.$router.push({
        path: "violenec",
      });
    },
    getHospitalName() {
      if (this.formData.hosp_code.length == 5) {
        this.showLoading();
        let data = {
          code: this.formData.hosp_code,
        };
        this.$store.dispatch("Auth/hospcode", data).then((res) => {
          if (res.data) {
            this.formData.hosp_name = res.data.name;
            this.hideLoading();
          } else {
            this.$swal.fire({
              title: "ไม่พบรหัสนี้",
              confirmButtonText: "ยืนยัน",
            });
          }
        });
      } else {
        this.formData.hosp_name = "";
      }
    },
    goto(path) {
      this.$router.push({
        path: path,
      });
    },
    save() {
      Swal.fire({
        title: "กำลังบันทึกข้อมูล",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          if (this.formData.abortion === "ฝากครรภ์") {
            if (!this.formData.antenatal_care_place) {
              Swal.fire({
                icon: "error",
                title: "กรุณาระบุสถานที่ฝากครรภ์",
                confirmButtonText: "ตกลง",
              });
            }
          }
          this.formData.user_id = this.user ? this.user.id : "";
          if (
            this.formData.service_status_denied !=
            "ส่งต่อไปรักษายังหน่วยงานอื่น ๆ"
          ) {
            this.formData.hosp_code = null;
            this.formData.hosp_name = null;
          }
          this.$store
            .dispatch("Pregnant/saveForm3", this.formData)
            .then((response) => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#4579DF",
                }).then(() => {
                  this.$router.push({
                    path: "result",
                  });
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "บันทึกไม่สำเร็จ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#4579DF",
                });
              }
            });
        },
      });
    },
    hideLoading() {
      this.$loading.hide();
      this.$swal.hideLoading();
    },
    showLoading() {
      this.$loading.show({
        background: this.$swal.fire({
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        }),
        animation: true,
      });
    },
    setData(model) {
      if (model.name) {
        this.formData[model.name] = model.value;
      }
    },
  },
  data() {
    return {
      search: null,
      model: null,
      hspSearch: null,
      hspModel: null,
      isLoading: false,
      hospitalList: [],
      isAdmin: false,
      mainForm: {
        oscc_code: 0,
      },
      service: [
        { name: "ให้คำปรึกษาอายุครรภ์ต่ำกว่า 12 สัปดาห์", grid: "col-12" },
        { name: "ให้คำปรึกษาอายุครรภ์ 12 - 20 สัปดาห์", grid: "col-12" },
        { name: "ให้คำปรึกษาอายุครรภ์ 20 สัปดาห์ขึ้นไป", grid: "col-12" },
      ],
      pregnant: [{ name: "ฝากครรภ์", key: "antenatal_care" }],
      abortion: [
        { name: "ฝากครรภ์", grid: "col-12" },
        { name: "ยุติการตั้งครรภ์ในโรงพยาบาล", grid: "col-12" },
        { name: "ส่งต่อ/แนะนำเครือข่ายยุติการตั้งครรภ์", grid: "col-12" },
        { name: "คุมกำเนิด", grid: "col-12" },
      ],
      abortionList: [
        {
          name: "ยุติการตั้งครรภ์ด้วยยา",
          key: "abortion_by_medicine",
          grid: "col-12",
        },
        {
          name: "ยุติการตั้งครรภ์ด้วยหัตการ",
          key: "abortion_by_procedure",
          grid: "col-12",
        },
      ],
      contraceptiveList: [
        { name: "ฝั่งยาคุม", grid: "col-12" },
        { name: "ใส่ห่วง", grid: "col-12" },
        { name: "ฉีด", grid: "col-12" },
        { name: "ทำหมัน", grid: "col-12" },
      ],
      isService: [
        { name: "อยู่ระหว่างดำเนินการ", grid: "col-12" },
        { name: "สิ้นสุดการให้บริการ", grid: "col-12" },
      ],
      endService: [
        { name: "ให้บริการครบตามที่วางแผน", grid: "col-12" },
        { name: "ปฏิเสธการรับบริการ", grid: "col-12" },
        { name: "เสียชีวิต", grid: "col-12" },
        { name: "ติดตามไม่ได้", grid: "col-12" },
        { name: "ส่งต่อไปรักษายังหน่วยงานอื่น ๆ", grid: "col-12" },
      ],
      formData: {
        form_id: "",
        user_id: "",
        service: "",
        hosp_code: "",
        hosp_name: "",
        abortion_process: "",
        consult_gestational_age_under_twelve: false,
        consult_gestational_age_under_twelve_twenty: false,
        consult_gestational_age_over_twenty: false,
        antenatal_care_place: "",
        antenatal_care: false,
        abortion: "",
        abortion_place: "",
        abortion_by_medicine: false,
        abortion_by_procedure: false,
        contraceptive: false,
        contraceptive_process: "",
        contraceptive_iud: false,
        contraceptive_implant: false,
        contraceptive_sterilization: false,
        service_status: "",
        service_status_complete: false,
        service_status_denied: "",
        service_status_death: false,
        service_status_not_track: false,
        service_status_forward: false,
        service_status_forward_hospital: "",
      },
    };
  },
};
</script>

<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
